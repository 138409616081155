import React, { useReducer, useState } from 'react'
import { makeStyles } from '@material-ui/core/styles'
import { Grid, Box, Button, FranchiseMenu, SiteDesktopMenu, Card, CardMedia, CardContent, Icon, Footer, FranchiseFooter, Dialog, Facebook, Instagram, Twitch, Twitter, Tab, Tabs, TabPanel } from 'tcweb-material-components/core'
import { library } from '@fortawesome/fontawesome-svg-core'
import get from 'tcweb-material-components/core/poly/get'

import brandportalLogo from '../assets/images/logos/brandportal.svg'
import gearsOfWarLogo from '../assets/images/logos/gearsOfWar-full-white.svg'

const useStyles = makeStyles(theme => ({
    icon: {
        padding: '10px 5px',
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
        textAlign: 'center'
    },
    logo: {
        height: '40px',
        [theme.breakpoints.only('md')]: {
            width: '150px', height: 'auto'
        }
    },
}))

const renderMenuItemImage = item => <img role='presentation' src={item.imgUrl} alt={item.title} />
const renderBrand = () => <img src={gearsOfWarLogo} alt='Gears of War logo'/>

function reducer(_, action) {
    switch (action.type) {
        case 'open':
            return { dialog: action.payload };
        case 'close':
            return { dialog: null }
        default:
            throw new Error();
    }
}

const initialState = { dialog: null }

const BRAND_ICONS   = Object.keys(get(library, 'definitions.tcb', []))
const REGULAR_ICONS = Object.keys(get(library, 'definitions.tcr', []))
const SOLID_ICONS   = Object.keys(get(library, 'definitions.tcs', []))

const UIPage = () => {
    const _classes = useStyles()
    const renderLogo = logo => () => <img src={logo} className={_classes.logo} alt='Logo' />
    const [state, dispatch] = useReducer(reducer, initialState)

    const openDialog = payload => () => dispatch({ type: 'open', payload })
    const closeDialog = () => dispatch({ type: 'close' })

    // Tabs
    const [tabs1Value, tabs1SetValue] = useState(0)
    const [tabs2Value, tabs2SetValue] = useState(0)

    return (
        <>
            <FranchiseMenu
                items={[
                    {
                        title: 'Games',
                        children: [
                            {
                                title: 'Kitten Game',
                                url: 'http://placekitten.com/364/500',
                                imgUrl: 'http://placekitten.com/364/500'
                            },
                            {
                                title: 'Kitten Game',
                                url: 'http://placekitten.com/364/500',
                                imgUrl: 'http://placekitten.com/364/500'
                            }
                        ]
                    },
                    { title: 'Merchandise', url: 'https://gearsofwar.com/store' },
                ]}
                renderBrand={renderBrand}
                renderMenuItemImage={renderMenuItemImage}
                SkipMenuButtonProps={{ title: 'Skip navigation' }}
            />

            <SiteDesktopMenu
                items={[
                    { title: 'Siamese', url: '#' },
                    { title: 'Bengal', url: '#' },
                    { title: 'Persian', url: '#'},
                ]}
                renderBrand={renderLogo(brandportalLogo)}
            />

            <Box gutters='page' color='primary'>
                <h1 style={{ margin: 0 }}>UI</h1>
                <hr />

                <h2>Buttons</h2>

                <Grid cols={{ xs: 1, md: 3 }}>
                    <div>
                        <h3>Text</h3>
                        <Button href='#'>Default</Button><br/>
                        <Button href='#' color='primary'>Primary</Button><br/>
                        <Button href='#' color='secondary'>secondary</Button><br/>
                        <Button href='#' color='tertiary'>tertiary</Button>
                    </div>

                    <div>
                        <h3>Contained</h3>
                        <Button href='#' variant='contained'>Default</Button><br/><br/>
                        <Button href='#' variant='contained' color='primary'>Primary</Button><br/><br/>
                        <Button href='#' variant='contained' color='secondary'>secondary</Button><br/><br/>
                        <Button href='#' variant='contained' color='tertiary'>tertiary</Button>
                    </div>

                    <div>
                        <h3>Outline</h3>
                        <Button href='#' variant='outline'>Default</Button><br/><br/>
                        <Button href='#' variant='outline' color='primary'>Primary</Button><br/><br/>
                        <Button href='#' variant='outline' color='secondary'>secondary</Button><br/><br/>
                        <Button href='#' variant='outline' color='tertiary'>tertiary</Button>
                    </div>
                </Grid>

                <h2>Boxes</h2>

                <Box>Default</Box>
                <Box color='primary'>Primary</Box>
                <Box color='secondary'>Secondary</Box>
                <Box color='tertiary'>Tertiary</Box>

                <h3>Gutters - Navigation</h3>
                <Box gutters='navigation'>Default</Box>
                <Box color='primary' gutters='navigation'>Primary</Box>
                <Box color='secondary' gutters='navigation'>Secondary</Box>
                <Box color='tertiary' gutters='navigation'>Tertiary</Box>

                <h3>Gutters - Page</h3>
                <Box gutters='page'>Default</Box>
                <Box color='primary' gutters='page'>Primary</Box>
                <Box color='secondary' gutters='page'>Secondary</Box>
                <Box color='tertiary' gutters='page'>Tertiary</Box>


                <h2>Cards</h2>
                <Card featured>
                    <CardMedia component='img' src='http://placekitten.com/1080/500' alt='Cat' />
                    <CardContent>
                        <h3>Default Featured</h3>
                        <p>
                            Hunt by meowing loudly at 5am next to human slave food dispenser. Sit in a box for hours cat snacks who's the baby, but be superior, but has closed eyes but still sees you so cough. You call this cat food. Lick the plastic bag morning beauty routine of licking self knock dish off table head butt cant eat out of my own dish and snuggles up to shoulders or knees and purrs you to sleep.
                        </p>
                        <Button variant='contained' href='http://placekitten.com/1080/500'>Click to see Kittens</Button>
                    </CardContent>
                </Card>

                <Grid scrollOn='sm' cols={3}>
                    <Card color='primary'>
                        <CardMedia component='img' src='http://placekitten.com/1080/500' alt='Cat' />
                        <CardContent>
                            <h3>Primary</h3>
                            <p>
                                Hunt by meowing loudly at 5am next to human slave food dispenser. Sit in a box for hours cat snacks who's the baby, but be superior, but has closed eyes but still sees you so cough. You call this cat food. Lick the plastic bag morning beauty routine of licking self knock dish off table head butt cant eat out of my own dish and snuggles up to shoulders or knees and purrs you to sleep.
                            </p>
                            <Button href='http://placekitten.com/1080/500'>Click to see Kittens</Button>
                        </CardContent>
                    </Card>
                    <Card color='secondary'>
                        <CardMedia component='img' src='http://placekitten.com/1080/500' alt='Cat' />
                        <CardContent>
                            <h3>Secondary</h3>
                            <p>
                                Hunt by meowing loudly at 5am next to human slave food dispenser. Sit in a box for hours cat snacks who's the baby, but be superior, but has closed eyes but still sees you so cough. You call this cat food. Lick the plastic bag morning beauty routine of licking self knock dish off table head butt cant eat out of my own dish and snuggles up to shoulders or knees and purrs you to sleep.
                            </p>
                            <Button variant='contained' href='http://placekitten.com/1080/500'>Click to see Kittens</Button>
                        </CardContent>
                    </Card>
                    <Card color='tertiary'>
                        <CardMedia component='img' src='http://placekitten.com/1080/500' alt='Cat' />
                        <CardContent>
                            <h3>Tertiary</h3>
                            <p>
                                Hunt by meowing loudly at 5am next to human slave food dispenser. Sit in a box for hours cat snacks who's the baby, but be superior, but has closed eyes but still sees you so cough. You call this cat food. Lick the plastic bag morning beauty routine of licking self knock dish off table head butt cant eat out of my own dish and snuggles up to shoulders or knees and purrs you to sleep.
                            </p>
                            <Button variant='outline' href='http://placekitten.com/1080/500'>Click to see Kittens</Button>
                        </CardContent>
                    </Card>

                    <Card variant='contained' color='primary'>
                        <CardMedia component='img' src='http://placekitten.com/1080/500' alt='Cat' />
                        <CardContent>
                            <h3>Default</h3>
                            <p>
                                Hunt by meowing loudly at 5am next to human slave food dispenser. Sit in a box for hours cat snacks who's the baby, but be superior, but has closed eyes but still sees you so cough. You call this cat food. Lick the plastic bag morning beauty routine of licking self knock dish off table head butt cant eat out of my own dish and snuggles up to shoulders or knees and purrs you to sleep.
                            </p>
                            <Button variant='contained' href='http://placekitten.com/1080/500'>Click to see Kittens</Button>
                        </CardContent>
                    </Card>
                    <Card variant='contained' color='secondary'>
                        <CardMedia component='img' src='http://placekitten.com/1080/500' alt='Cat' />
                        <CardContent>
                            <h3>Secondary</h3>
                            <p>
                                Hunt by meowing loudly at 5am next to human slave food dispenser. Sit in a box for hours cat snacks who's the baby, but be superior, but has closed eyes but still sees you so cough. You call this cat food. Lick the plastic bag morning beauty routine of licking self knock dish off table head butt cant eat out of my own dish and snuggles up to shoulders or knees and purrs you to sleep.
                            </p>
                            <Button variant='contained' color='tertiary' href='http://placekitten.com/1080/500'>Click to see Kittens</Button>
                        </CardContent>
                    </Card>
                    <Card variant='contained' color='tertiary'>
                        <CardMedia component='img' src='http://placekitten.com/1080/500' alt='Cat' />
                        <CardContent>
                            <h3>Tertiary</h3>
                            <p>
                                Hunt by meowing loudly at 5am next to human slave food dispenser. Sit in a box for hours cat snacks who's the baby, but be superior, but has closed eyes but still sees you so cough. You call this cat food. Lick the plastic bag morning beauty routine of licking self knock dish off table head butt cant eat out of my own dish and snuggles up to shoulders or knees and purrs you to sleep.
                            </p>
                            <Button variant='outline' color='primary' href='http://placekitten.com/1080/500'>Click to see Kittens</Button>
                        </CardContent>
                    </Card>
                </Grid>

                <h2>Icons</h2>

                <Grid cols={{ xs: 2, md: 8 }}>
                    {BRAND_ICONS.map(icon => (
                        <div key={`tcb-${icon}`} className={_classes.icon}>
                            <Icon iconBase='gears' iconPrefix='tcb' iconName={icon} />
                            tcb-{icon}
                        </div>
                    ))}
                    {REGULAR_ICONS.map(icon => (
                        <div key={`tcr-${icon}`} className={_classes.icon}>
                            <Icon iconBase='gears' iconPrefix='tcr' iconName={icon} />
                            tcr-{icon}
                        </div>
                    ))}
                    {SOLID_ICONS.map(icon => (
                        <div key={`tcs-${icon}`} className={_classes.icon}>
                            <Icon iconBase='gears' iconPrefix='tcs' iconName={icon} />
                            tcs-{icon}
                        </div>
                    ))}
                </Grid>

                 <h2>Tabs</h2>
                 <Tabs value={tabs1Value}  onChange={(event, newValue) => tabs1SetValue(newValue)} aria-label='scrollable auto tabs example'>
                    <Tab label='Item One' />
                    <Tab label='Item Two' />
                    <Tab label='Item Three' />
                    <Tab label='Item Foursdfdsfdsfdsffd' />
                </Tabs>
                <TabPanel value={tabs1Value} index={0}>
                    Item One
                </TabPanel>
                <TabPanel value={tabs1Value} index={1}>
                    Item Two
                </TabPanel>
                <TabPanel value={tabs1Value} index={2}>
                    Item Three
                </TabPanel>
                <TabPanel value={tabs1Value} index={3}>
                    Item Four
                </TabPanel>

                <Tabs value={tabs2Value} indicatorType='pill' onChange={(event, newValue) => tabs2SetValue(newValue)} aria-label='scrollable auto tabs example'>
                    <Tab label='Item One' />
                    <Tab label='Item Two' />
                    <Tab label='Item Three' />
                    <Tab label='Item Foursdfdsfdsfdsffd' />
                </Tabs>
                <TabPanel value={tabs2Value} index={0}>
                    Item One
                </TabPanel>
                <TabPanel value={tabs2Value} index={1}>
                    Item Two
                </TabPanel>
                <TabPanel value={tabs2Value} index={2}>
                    Item Three
                </TabPanel>
                <TabPanel value={tabs2Value} index={3}>
                    Item Four
                </TabPanel>

                <h2>Dialog</h2>
                <Button onClick={openDialog('default')}>Default Dialog</Button><br />
                <Button onClick={openDialog('primary')}>Primary Dialog</Button><br />
                <Button onClick={openDialog('secondary')}>Secondary Dialog</Button><br />
                <Button onClick={openDialog('tertiary')}>Tertiary Dialog</Button>

                <Dialog
                    title={`${state.dialog} Dialog`}
                    onClose={closeDialog}
                    color={state.dialog}
                    open={!!state.dialog}
                >
                    <Card>
                        <CardMedia><img src="http://placekitten.com/1080/500" alt="Cat" /></CardMedia>
                        <CardContent>
                            <h3>Primary</h3>
                            <p>
                                Hunt by meowing loudly at 5am next to human slave food dispenser. Sit in a box for hours cat snacks who's the baby, but be superior, but has closed eyes but still sees you so cough. You call this cat food. Lick the plastic bag morning beauty routine of licking self knock dish off table head butt cant eat out of my own dish and snuggles up to shoulders or knees and purrs you to sleep.
                            </p>
                        </CardContent>
                    </Card>
                </Dialog>

                <h2>Social Media Icons</h2>
                <Facebook />
                <Instagram />
                <Twitch />
                <Twitter />
            </Box>

            <Footer>
                <FranchiseFooter>
                    <div>
                        <img src='http://placekitten.com/228/55' alt='Franchise' /> Footer Franchise
                    </div>

                    <div><Button>Coalition</Button> | <Button>XBOX Game Studios</Button></div>

                    <div><Button>Support</Button> | <Button>Forums</Button></div>

                    <div>
                        <Icon iconBase='font awesome' iconPrefix='fab' iconName='twitter' />
                    </div>
                </FranchiseFooter>
            </Footer>
        </>
    )
}

export default UIPage